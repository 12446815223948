/*Unity styles*/

/*canvas##canvas {*/
/*  width: 100%;*/
/*  height: 240px;*/
/*}*/

/*GRNet Styles*/

div.eventDetailsRow {
  margin-top:30px;
}

div.backgroundImage {
  position: absolute;
  width: 100%;
  height: 500px;
  background: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  z-index: -100;
}

div.backgroundImage::before {
  content: "";
  position: absolute;
  z-index: -98;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px); /* apply the blur */
  backdrop-filter: blur(8px);         /* apply the blur */
  pointer-events: none; /* make the pseudo class click-through */
}

.Callout-styles-module--warning--39Zw- {
  background-color: rgba(255,229,100,.3);
}

.Callout-styles-module--callout--1_K3h {
  border-left: 4px solid transparent;
  border-left-color: #ffe564;
  padding: 1.2rem 1.2rem 1.2rem calc(1rem - 4px);
  margin-left: -1rem;
  margin-bottom: 2rem;
  text-align: left;
}

#vrCodeInput {
  width:4em;
  text-align: center;
  font-size: 5rem;
  margin-left: auto;
  margin-right:auto;
}

.metronome-mute-btn {
  margin-bottom: 5px;
}

.metronome-slider {
  flex-grow: 0.9;
}

footer {
  background-color: #212529;
  color: #afafaf;
}

.small {
  height: 3.9rem;
}

.small .h5 {
  font-size: 0.7rem;
  margin-bottom: .5rem;
}

.small .h6 {
  font-size: 0.7rem;
  margin-bottom: .05rem;
}

.small .card-body {
  padding: 0.5rem;
}

.card .small {
  margin: 0;
}

.small .itemImageContainer {
  min-width: 55px;
  max-width: min-content;
  margin: auto;
}

.recommendations {
  background-color: #FFFFFF;
  width: 230px;
  display: flex;
  flex-direction: column;
}

.recommsHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 28px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #797979;
}

.recommsContainer {
  flex: 1;
  overflow-y: scroll;
}

.navbar-brand {
  color: #c8c8c8 !important;
}

.metronome {
  margin-left: 24px;
}

.wideShotPianoContainer {
  
  /*left: 0;*/
}

.topPianoContainer {
  flex: 1;
  margin: auto;
}

.bottomPianoContainer {
  height: 190px;
}

.bottomPianoSection {
  position:absolute; right:0;left:0;
  height: 246px;
  bottom: 0;
}

.topHalfContainer {
  top:60px;
  bottom:246px;
  position:absolute; right:0;left:0;
  display: flex;
  width: 100%;
  background-color: #000000;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 60px;
}

.navbar form {
  width: 100% !important;
  height: 100%;
  padding-right: 20px;
  padding-left: 10px;
}

.navSearch {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
  width: 100% !important;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height:100%;
}

.nav-link {
  min-width: 4.5rem;
}

.searchResultsContainer .col-2,.searchResultsContainer .col {
  padding: 0;
}

.searchResultsContainer .row {
  margin-right: 0;
  margin-left: 0;
}

.searchResultsContainer .card {
  transition: 0.3s;
}

.searchResultsContainer .card:hover {
  background-color: #b0b0b0;
}

.searchInputContainer {
  height: 100%;
  width: 100%;
}

ul.searchResultsContainer {
  background-color: #D8D8D8;
  position: relative;
  width: 100%;
  z-index: 999;
  padding: 0;
}

/*Main App Styles*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 80px;
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
